@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.modal-backdrop {
  background: linear-gradient(97.63deg, #097a57 -11.1%, #73bf43 100%);
  opacity: 1;
}
.headerSection {
  min-height: calc(100vh - 85px);
}
@media only screen and (max-width: 1024px) {
  .headerSection {
    min-height: calc(100vh - 325px);
  }
  .mobileImage {
    margin-left: -30px;
  }
}
@media only screen and (max-width: 1024px) {
  .headerSection {
    padding-top: 2rem;
  }
}
@media only screen and (max-width: 768px) {
  .displayNone {
    display: none;
  }
  .centerReveal .react-reveal {
    display: flex;
    justify-content: center;
  }
  .footerMediaLinks {
    width: 75%;
    margin-left: 0px !important;
    justify-content: space-around;
    margin-top: 6px;
  }
}

@media only screen and (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 900px;
  }
}

a {
  color: white;
}

button {
  color: inherit;
  border: none;
  background: none;
  cursor: pointer;
}
button:focus {
  outline: none;
}

.round {
  position: relative;
}

/* animation */
#containera {
  height: 15vh;
  width: 20vw;
  position: absolute;
  bottom: 0;
  left: 0px;
}

@media only screen and (max-width: 410px) {
  #containera {
    height: 20vh;
    width: 60vw;
    position: absolute;
    bottom: 0;
    left: 40px;
  }
}

#target {
  position: absolute;
}

.main {
  width: 30%;
  height: 100px;
  position: absolute;
  bottom: 30%;
  left: 50%;
}

.circle {
  width: 30px;
  height: 30px;
  background: #b2d8b2;
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  margin: auto;
  animation: filledcircle 32s linear infinite;
}
.dBox {
  animation: rotate 40s linear infinite;
}

.borderCircle {
  position: absolute;
  top: 20%;
  left: 40%;
  z-index: -1;
  animation: circle 22s linear infinite;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

@keyframes circle {
  0% {
    transform: rotate(0deg) translate(-105px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translate(-105px) rotate(-360deg);
  }
}

@keyframes filledcircle {
  0% {
    transform: rotate(0deg) translate(-165px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translate(-165px) rotate(-360deg);
  }
}

/* animation ends */

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 6px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #047350;
  border-color: #047350;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.stickyNav {
  position: fixed;
  z-index: 9;
  left: 0px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  top: -75px;
  transform: translateY(75px);
  transition: transform 0.3s;
}

.navbar-toggler:focus {
  box-shadow: none;
}
.navbar-toggler {
  border: none;
}

.arrowIcon {
  margin-left: 12px;
}

.swiper-pagination {
  position: relative;
  margin-top: 24px;
}

.swiper-pagination-bullet {
  height: 10px;
  width: 10px;
  background: #097a57;
  border-radius: 50%;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background: #097a57;
  width: 32px;
  height: 10px;
  border-radius: 12px;
}

.listMarker {
  list-style: none;
  padding-left: 1.4rem;
  margin-top: 16px;
}
.listMarker li {
  position: relative;
  margin-bottom: 4px;
}

.listMarker li::before {
  content: "\2022";
  color: #097a57;
  display: inline-block;
  position: absolute;
  left: -1.5rem;
  font-size: 1.6rem;
  top: -8px;
}

.custom-select-container {
  display: inline-block;
  min-width: 250px;
  position: relative;
  width: 100%;
  border: 1px solid #000;
  border-radius: 8px;
  min-height: 40px;
}

.selected-text {
  background-color: #fff;
  padding: 8px 16px;
  border-radius: 8px;
  min-height: 40px;
}

.selected-text::after {
  content: "";
  position: absolute;
  right: 20px;
  top: 16px;
  border: 7px solid transparent;
  border-color: #000 transparent transparent transparent;
}

.selected-text.active::after {
  top: 8px;
  border-color: transparent transparent #000 transparent;
}

.custom-select-container ul {
  margin: 0;
  padding: 0;
  text-align: left;
}

.select-options {
  position: absolute;
  width: 100%;
  top: 0px;
  border: 1px solid #000;
  border-radius: 8px;
  overflow: hidden;
  z-index: 4;
}

.custom-select-container li {
  list-style-type: none;
  padding: 6px 20px;
  background: #fff;
  border-bottom: 1px solid #f1f1f1;
  cursor: pointer;
  text-align: left;
}

.custom-select-container li:hover {
  background-color: #f1f1f1;
}

@media only screen and (max-width: 768px) {
  .whiteColor {
    color: white;
  }
}

.image {
  display: block;
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

/* .loading {
  filter: blur(10px);
  clip-path: inset(0);
} */

.loaded {
  filter: blur(0px);
  transition: filter 0.5s linear;
}

/* //loading */
.loading {
  display: inline-block;
  position: relative;
  width: 27px;
  height: 27px;
}
.loading div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 27px;
  height: 27px;
  margin: 0px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.loading div:nth-child(1) {
  animation-delay: -0.45s;
}
.loading div:nth-child(2) {
  animation-delay: -0.3s;
}
.loading div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* youtubeplay */
.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
